<template>
    <div>
      <h1>Page B</h1>
      <p>
        Ab accusamus cupiditate id minus possimus qui sunt? Alias deserunt doloribus ducimus eos esse fugit magnam, molestias. In non porro repellendus temporibus?
      </p>
    </div>

</template>

<script>
export default { name: 'PageB' }
</script>
