<template>
    <div>
      <h1>Page C</h1>
      <p>
        In non porro repellendus temporibus ? Ab accusamus cupiditate id minus possimus qui sunt? Alias deserunt doloribus ducimus eos esse fugit magnam, molestias.
      </p>
    </div>

</template>

<script>
export default { name: 'PageC' }
</script>
