<template>
  <div class="column container">
    <h1>Page introuvable</h1>
    <p>Erreur 404</p>
  </div>
</template>

<script>
export default {}
</script>
