<template>

  <main>
    <users></users>
  </main>

</template>

<script>
import Users from '../components/Users'
export default {
  name: 'PageUsers',
  components: { Users }
}
</script>
